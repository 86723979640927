import React from 'react';
import './music.css'

const Music = () => {
    return (
        <div id="music">

            <div className="container d-flex align-items-center justify-content-center fade-in-fwd music-container p-3">
                <div className="row d-flex align-items-center justify-content-center pt-5 pb-5">
                    <div className="col-12">
                        <h1 className="text-white display-1 text-center my-display-1">
                            Traeger Winn
                        </h1>
                    </div>
                    <div className="col-12 text-center pt-1 pb-1">
                        <h1 className="text-secondarys display-3 text-center my-display-3">
                            Music
                        </h1>
                    </div>
                    <div className="col-12 text-center pt-2 pb-2">
                        <a name="apple" href="https://music.apple.com/us/artist/traeger-winn/1800094641">
                            <i className="fa fa-apple fa-4x text-secondary my-icon p-3" aria-hidden="true"></i>
                        </a>
                    </div>

                </div>  
            </div>

        </div>
    );
};
export default Music;